// ------------------------------------------------------------------
//
// MODAL
//
// ------------------------------------------------------------------

#redactor-modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	margin: auto;
	overflow: auto;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, .95);
}
#redactor-modal-box {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	overflow-x: hidden;
	overflow-y: auto;
}
#redactor-modal {

	position: relative;
	margin: auto;
	margin-top: 16px;
	padding: 0;
	background: #fff;
	color: #000;
	border-radius: 5px;
	box-shadow: 0 4px 40px rgba(0, 0, 0, .15);
	font-family: $ui-font-family;
	font-size: 14px;

	@media (max-width: 768px) {
	    font-size: 16px !important;
	}

	& #redactor-modal-header {
		border-top-right-radius: 5px;
		border-top-left-radius: 5px;
		padding: 20px;
		font-size: 18px;
		font-weight: bold;
		color: #000;
		border-bottom: 1px solid rgba(0, 0, 0, .05);
	}
	& #redactor-modal-close {
		position: absolute;
		top: 10px;
		right: 4px;
		width: 30px;
		height: 40px;
		text-align: center;
		color: rgba(0, 0, 0, .3);
		font-size: 30px;
		font-weight: 300;
		cursor: pointer;
		-webkit-appearance: none;
		padding: 0;
		border: 0;
		background: none;
		outline: none;
		&:hover {
			color: #000;
		}
	}
	& #redactor-modal-body {
		overflow: auto;
		padding: 40px 48px 24px 48px;

		& section {
			margin-bottom: 24px;
		}
		& label {
			font-weight: normal;
			font-size: 15px;
			display: block;
			margin-bottom: 4px;
			color: rgba(0, 0, 0, .8);
			& .desc {
				color: rgba(0, 0, 0, .5);
			}
			&.checkbox {
				font-size: inherit;
			}
		}

		@media (max-width: 768px) {
		    padding: 24px;
		}
	}

	& select,
	& input[type="text"],
	& input[type="password"],
	& input[type="email"],
	& input[type="url"],
	& input[type="number"],
	& textarea {
		box-sizing: border-box;
		position: relative;
		z-index: 2;
		margin: 0;
		padding: 8px;
		height: 40px;
		border: 1px solid #ddd;
		border-radius: 3px;
		background-color: white;
		box-shadow: none;
		color: #333;
		width: 100%;
		font-size: 16px;

		font-family: $ui-font-family;

		&:focus {
			@include transition-redactor(border 0.3s ease-in);
			outline: none;
			border-color: rgba(31,120,216,1);

		}
	}
	& textarea {
		display: block;
		line-height: 1.4em;
	}

	& button {
		padding: 0 20px;
		margin: 0;
		margin-bottom: 8px;
		margin-right: 8px;
		height: 40px;
		line-height: 38px;
		outline: none;
		border: 2px solid transparent;
		border-radius: 3px;
		background-color: #eee;
		color: #000;
		text-align: center;
		text-decoration: none;
		font-weight: normal;
		font-size: 15px;
		font-family: $ui-font-family;
		cursor: pointer;
		vertical-align: middle;
        -webkit-appearance: none;

		&:hover {
			color: #777;
			background: none;
			background-color: #f5f5f5;
			text-decoration: none;
		}

		&.redactor-modal-button-offset {
			margin-left: 24px;
		}

		&#redactor-modal-button-delete {
			background: none;
			border-color: #f23d3d;
			color: #f23d3d;
			&:hover {
				color: rgba(255, 255, 255, .6);
				background-color: #f23d3d;
			}
		}
		&#redactor-modal-button-action {
			background: none;
			color: #fff;
			background-color: #1c86f2;
			&:hover {
				color: rgba(255, 255, 255, .6);
				background-color: lighten(#1c86f2, 10%);
			}
		}
	}

	& .redactor-group {
		@include clearfix;
	}
	& .redactor-modal-tab-side {
		float: left;
		width: 26%;
		margin-right: 6%;
	}
	& .redactor-modal-tab-area {
		float: left;
		width: 66%;
	}

	@media (max-width: 768px) {
		& .redactor-modal-tab-side {
			float: none;
			width: auto;
			margin-right: 0;
			margin-bottom: 24px;
		}
		& .redactor-modal-tab-area {
			float: none;
			width: auto;
		}
	}
}

#redactor-modal-tabber {
	margin-bottom: 40px;
	font-size: 12px;
	& a {
		border: 1px solid #ddd;
		line-height: 1;
		padding: 8px 15px;
		margin-right: -1px;
		text-decoration: none;
		color: #000;
		&:hover {
			background-color: rgba(31,120,216,1);
			border-color: rgba(31,120,216,1);
			color: #fff;
		}
		&.active {
			cursor: default;
			background-color: rgba(0, 0, 0, .05);
			border-color: #ddd;
			color: rgba(0, 0, 0, .5);
		}
	}
}

#redactor-modal-list {
	margin-left: 0;
	padding-left: 0;
	list-style: none;
	max-height: 250px;
	overflow-x: auto;
	& li {
		border-bottom: 1px solid rgba(0, 0, 0, .07);
		&:last-child {
			border-bottom: none;
		}
	}
	& a {
		padding: 16px 4px;
		color: #000;
		text-decoration: none;
		font-size: 15px;
		display: block;
		position: relative;
		&:hover {
			background-color: #eee;
		}
	}
}