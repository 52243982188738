@import '~select2/dist/css/select2.min.css';
@import '../../resources/shared/redactor/_scss/redactor.scss';

body {
    font-size: 0.8rem;
}

.checkbox-padding {
    padding-bottom: 16px;
}

.filters {
    gap: 20px;
}


td .tag {
    float: right;
    background: #000;
    color: #fff;
    padding: 2px 8px;
    border-radius: 16px;
    font-size: 10px;

    &.orange {
        background: #d88a0e;
    }

    &.green {
        background: #07ba53;
    }
}

.wizard-steps {
    display: flex;
    width: 100%;
    justify-content: space-between;

    li {
        display: flex;
        border-radius: 4px;
        width: 9rem;
        height: 5rem;
        border: 3px solid transparent;
        background-color: #d5d5d5;
        justify-content: center;
        align-items: center;
        text-align: center;

        &.active {
            border-color: #357CE3;
        }
    }
}
