// ------------------------------------------------------------------
//
// DROPDOWN
//
// 01. Base
// 02. InlineFormat
// 03. BlockFormat
//
// ------------------------------------------------------------------

// =Base
// ------------------------------------------------------------------
.redactor-dropdown {

    font-family: $ui-font-family;
	min-width: 220px;
	max-height: 254px;
	color: #000;
	margin: 0;
	padding: 0;
	font-size: 14px;
	background: #fff;
	box-shadow: 0 5px 20px rgba(0, 0, 0, .2);
	overflow: auto;
	list-style: none;
}
.redactor-dropdown li {

	border-bottom: 1px solid rgba(0, 0, 0, .07);

	&:last-child {
		border-bottom: none;
	}
	&:hover {
		background-color: #3d79f2;
		& a {
			color: #fff !important;
			text-decoration: none;
		}
	}
    &.redactor-dropdown-link-inactive {

        background: none;

    	& a,
        & a:hover {
            background: none;
            cursor: default;
            color: #000 !important;
            opacity: 0.4;
            background: none;
        }
    }
}
.redactor-dropdown a {

	display: block;
	padding: 12px 10px;
	color: #000;
	text-decoration: none;

	& span {
		padding: 2px 4px 1px 4px;
		display: inline-block;
		border-radius: 3px;
		line-height: 1;
	}
	&:focus {
		outline: none;
	}
	&.selected {
		background-color: #000;
		color: #fff;
	}
	&.redactor-dropdown-link-selected {
		color:#fff;
		background:#000;
	}
}


// InlineFormat
// ------------------------------------------------------------------
.redactor-dropdown-box-inline {

	& .redactor-dropdown-marked span {
		background-color: $mark-background-color;
		color: #000;
		text-decoration: none;
	}
	& .redactor-dropdown-code span {
		font-family: $monospace-font-family;
		background: $code-background-color;
	}
	& .redactor-dropdown-sample span {
		font-family: $monospace-font-family;
		background: #46a9fc;
		color: rgba(255, 255, 255, .9);
	}
	& .redactor-dropdown-variable span {
		font-family: $monospace-font-family;
		color: rgba(0, 0, 0, .5);
	}
	& .redactor-dropdown-shortcut span {
		font-family: $monospace-font-family;
		background: #000;
		color: rgba(255, 255, 255, .85);
		white-space: nowrap;
	}
	& .redactor-dropdown-cite span {
		color: rgba(0, 0, 0, .5);
		font-style: italic;
	}
	& .redactor-dropdown-sup span,
	& .redactor-dropdown-sub span {
		font-size: 12px;
	}

}


// BlockFormat
// ------------------------------------------------------------------
.redactor-dropdown-box-format {
	& .redactor-dropdown-blockquote {
		color: rgba(0, 0, 0, .4);
		font-style: italic;
	}
	& .redactor-dropdown-pre {
		font-family: monospace, sans-serif;
	}
	& .redactor-dropdown-h1,
	& .redactor-dropdown-h2,
	& .redactor-dropdown-h3,
	& .redactor-dropdown-h4,
	& .redactor-dropdown-h5,
	& .redactor-dropdown-h6 {
		line-height: $base-line;
		font-weight: bold
	}
	& .redactor-dropdown-h1 {
		font-size: $font-size-h1;
		line-height: 32px;
	}
	& .redactor-dropdown-h2 {
		font-size: $font-size-h2;
		line-height: 32px;
	}
	& .redactor-dropdown-h3 {
		font-size: $font-size-h3;
	}
	& .redactor-dropdown-h4 {
		font-size: $font-size-h4;
	}
	& .redactor-dropdown-h5 {
		font-size: $font-size-h5;
	}
	& .redactor-dropdown-h6 {
		font-size: $font-size-h6;
	}
}